






























































import { News } from "@/api"
import { Component, Vue } from "vue-property-decorator"
import { URLManager } from "@/services/Managers"

@Component({
  components: {
    AddNewsModal: () => import("@/components/AddNewsModal.vue")
  }
})
export default class NewsInterface extends Vue {
  news: any = {}

  get tableColumns() {
    return [
      { key: "id" },
      { key: "tags" },
      { key: "title" },
      { key: "description" },
      { key: "actions" }
    ]
  }

  async getNews() {
    try {
      const { result } = await News.getAllNews()
      this.news = result.sort((a, b) => b.createdAt - a.createdAt)
    } catch (e) {
      console.error(e)
    }
  }

  async deleteNews(id: number) {
    const response = await News.deleteNews(id)

    if (response.success) {
      this.$root.$emit("refreshNews")
    }
  }

  async mounted() {
    await this.getNews()
    this.$root.$on("refreshNews", async () => await this.getNews())
    URLManager.updateURLParams("")
  }
}
